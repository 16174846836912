$(function() {
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $("#page-top").fadeOut();
    } else {
      $("#page-top").fadeIn();
    }
  }
  $(window).on("load scroll", displayToTop);

  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('nav').removeClass('open');
      $('.overlay').removeClass('open');
      $('body').removeClass('sp-menu-open');
    } else {
      $(this).addClass('active');
      $(this).next('nav').addClass('open');
      $('.overlay').addClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('.overlay, nav.open').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('header nav').removeClass('open');
      $('body').removeClass('sp-menu-open');
      $('.gnav ul .pulldown').removeClass('spread');
    }
  });
  $('nav').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.overlay').removeClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('ul.gnav > li > span').on('click',function(){
    if($('+ .pulldown',this).hasClass('spread')){
      $('+ .pulldown',this).removeClass('spread');
    } else {
      $('+ .pulldown',this).addClass('spread');
    }
  });

  // ホーム：スライダー
  if ($('body').hasClass('home')){
    $('.slider').slick({
      autoplay:true,
      arrows: false,
      dots: true,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 1000,
      slidesToShow:1,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'33%',
      swipeToSlide:true,
      touchThreshold:10,
      responsive: [{
        breakpoint: 1600,
        settings: {
        centerPadding:'25%'
        }
      },{
        breakpoint: 960,
        settings: {
        centerPadding:'18%'
        }
      },{
        breakpoint: 768,
        settings: {
          speed: 500,
          centerPadding:'5%'
        }
      }]
    });
  }
  // スライダー
  if ($('body').hasClass('location')){
    $('.slider').slick({
      autoplay:true,
      arrows: false,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:1,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'20%',
      responsive: [{
        breakpoint: 768,
        settings: {
          speed: 500,
          slidesToShow: 1
        }
      }]
    });
  }
  if ($('body').hasClass('service')){
    $('.slider').slick({
      autoplay:true,
      arrows: false,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:2,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'20%',
      responsive: [{
        breakpoint: 768,
        settings: {
          speed: 500,
          slidesToShow:2,
          centerPadding:'5%'
        }
      }]
    });
  }
  if ($('body').hasClass('about')){
    $('.slider').slick({
      autoplay:true,
      arrows: false,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:1,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'30%',
      responsive: [{
        breakpoint: 768,
        settings: {
          speed: 500,
          centerPadding:'5%'
        }
      }]
    });
  }
  if ($('body').hasClass('location')) {
    $('#osouzai').load('/service/1.html #load-contents',function(){
      $('div.slider').slick({
        autoplay:true,
        arrows: false,
        autoplaySpeed:5000,
        pauseOnHover: false,
        speed: 2000,
        slidesToShow:2,
        slidesToScroll:1,
        centerMode: true,
        centerPadding:'20%',
        responsive: [{
          breakpoint: 768,
          settings: {
            speed: 500,
            slidesToShow:2,
            centerPadding:'5%'
          }
        }]
      });
    });
  }
  // copyright年
  var copyrightY = (new Date).getFullYear();
  $("footer .copyright span").text(copyrightY);
});
